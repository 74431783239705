import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Breadcrumbs:`}</h2>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`> Campaigns > Add `}</li>
    </ul>
    <h2>{`Tab: `}<em parentName="h2">{`Passive elements`}</em></h2>
    <p><em parentName="p">{`Passive elements`}</em>{` are elements presented in a page(s), within its content, by a placeholder (needs to insert a string code within a page).`}</p>
    <p>{`The elements available are:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Action`}</em>{`: Interactive campaign requiring visitor actions`}</li>
      <li parentName="ul"><em parentName="li">{`Banner with actions`}</em>{`: This element enables you to display custom banners with CoreMedia actions in your website`}</li>
      <li parentName="ul"><em parentName="li">{`Banner with link`}</em>{`: This element enables to display custom banners in your website`}</li>
      <li parentName="ul"><em parentName="li">{`Banner with contebt`}</em>{`: Display banners with content
-`}<em parentName="li">{` Calltracker`}</em>{`: Allow us to track inbound calls with the same data we gather in Click2Call and to identify traffic origins and visitors`}</li>
      <li parentName="ul"><em parentName="li">{`Click2Call via JS`}</em>{`: Click2Call client-side via JS `}</li>
      <li parentName="ul"><em parentName="li">{`Contact window`}</em>{`: Contact windows directly embedded in your browser.`}</li>
      <li parentName="ul"><em parentName="li">{`Cookie - Service Level selector`}</em>{`: This widget allows to display a service level selector to the visitor. The decision taken by the visitor will be recorded in the visitor cookie and will be used to manage Tag insertion and Elements presentation.`}</li>
      <li parentName="ul"><em parentName="li">{`Custom content`}</em>{`: This Element lets you enter custom content - HTML, images and Flash.`}</li>
      <li parentName="ul"><em parentName="li">{`E-commerce recommended products`}</em>{`: Recommended products`}</li>
      <li parentName="ul"><em parentName="li">{`Filesharing`}</em>{`: Allows file sharing`}</li>
      <li parentName="ul"><em parentName="li">{`Inline surveys`}</em>{`: Surveys directly inserted into the page`}</li>
      <li parentName="ul"><em parentName="li">{`Leads ads`}</em>{`: Lead ads generation`}</li>
      <li parentName="ul"><em parentName="li">{`Newsletter subscription`}</em>{`: Newsletter subscription directly in your page`}</li>
      <li parentName="ul"><em parentName="li">{`Newsletter subscription - confirmation`}</em>{`: Newsletter subscription confirmation directly in your page`}</li>
      <li parentName="ul"><em parentName="li">{`Newsletter unsubscription`}</em>{`: Newsletter unsubscription directly in your page`}</li>
      <li parentName="ul"><em parentName="li">{`Newsletter unsubscription - confirmation`}</em>{`: Newsletter unsubscription confirmation directly in your page`}</li>
      <li parentName="ul"><em parentName="li">{`Opt Out`}</em>{`: Opt Out`}</li>
      <li parentName="ul"><em parentName="li">{`Retargeting cookie`}</em>{`: Retargeting cookie inserted into the client domain to be read by third parties`}</li>
      <li parentName="ul"><em parentName="li">{`Smart window`}</em>{`: Presentation of extended contact windows with Ecommerce information, Surveys, etc`}</li>
      <li parentName="ul"><em parentName="li">{`Survey answer`}</em>{`: Presentation of responses to surveys or unique questions`}</li>
      <li parentName="ul"><em parentName="li">{`Vouchers`}</em>{`: Vouchers`}</li>
    </ul>
    <h2>{`Tab: `}<em parentName="h2">{`Inline elements`}</em></h2>
    <p>{`An `}<em parentName="p">{`inline element`}</em>{` is an HTML element that fits within the flow of text on a webpage, only taking up as much space as needed. In campaigns, inline elements are used to dynamically update specific content after the DOM has loaded, ensuring seamless alterations without disrupting the page layout. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`NAME`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Name of the element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`STATUS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`BASE URL ADDRESS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - URL address`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Tab: `}<em parentName="h2">{`Active elements`}</em></h2>
    <p><em parentName="p">{`Active elements`}</em>{` are elements presented in a page(s), over its content, by a trigger or time and client doesn’t need to add anything. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`ELEMENT TYPE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` -  `}<em parentName="td">{`Active element`}</em>{` or `}<em parentName="td">{`Abandonment element`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`NAME`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Name of the element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`STATUS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`ACTIVE PLACEHOLDER`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`-new placeholder-`}</em>{` or a placeholder from the list`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Tab: `}<em parentName="h2">{`Settings`}</em></h2>
    <p>{`In the `}<em parentName="p">{`Settings`}</em>{` tab you can configure the tag, time period of the campaign or the contact list associated.   `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`TAG`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`VALIDATE VISITOR TYPE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`All visitors`}</em>{`, `}<em parentName="td">{`Only identified visitors`}</em>{` or `}<em parentName="td">{`Only anonymous visitors`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`LIMIT CAMPAIGN TYPE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`VALIDATE RESPECTING BUSINESS HOURS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`LIMIT TIME SCHEDULE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`VALIDATE AVAILABLE VOICE CREDITS (MONTHLY)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`VALIDATE AVAILABLE VOICE CREDITS (DAILY)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`ASSOCIATE CONTACT LIST`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`-none-`}</em>{` or from the list`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Tab: `}<em parentName="h2">{`Segmentation`}</em></h2>
    <p>{`The `}<em parentName="p">{`Segmentation`}</em>{` Tab is where you can define the rules by which the campaign will be presented such as url, group of pages, resolution, browser, external campaign, etc. In addition to the definitions referred to, we have several other layout and configuration options. These options should be evaluated case by case.   `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`STATUS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`ADD CONDITION`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - When status is `}<em parentName="td">{`on`}</em></td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Segmentation conditions`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`User info`}</li>
        <li parentName="ul">{`User activity`}</li>
        <li parentName="ul">{`Page`}</li>
        <li parentName="ul">{`Contacts`}</li>
        <li parentName="ul">{`Events`}</li>
        <li parentName="ul">{`Session`}</li>
        <li parentName="ul">{`OnSite Elements`}</li>
        <li parentName="ul">{`Forms`}</li>
        <li parentName="ul">{`System`}</li>
        <li parentName="ul">{`Lead Score`}</li>
        <li parentName="ul">{`Segmentation settings (Check `}<a parentName="li" {...{
            "href": "/configure-segmentation-settings.md"
          }}><em parentName="a">{`Configure segmentation on settings`}</em></a>{` for additional information.)`}</li>
        <li parentName="ul">{`NPS`}</li>
        <li parentName="ul">{`Push Notifications`}</li>
      </ul>
      <br /> These options should be evaluated case by case. It is possible to use combined rules ("or"/"and"). 
    </blockquote>
    <blockquote>
      <br /> Some examples:
    </blockquote>
    <blockquote>
      <ol parentName="blockquote">
        <li parentName="ol">{`The campaign is available in the group of pages included in the "Homepage" and excluded from the pages included in the "Exclusions" group, which can be configured in `}<em parentName="li">{`Settings`}</em>{` > `}<em parentName="li">{`Tags and names`}</em>{` > `}<em parentName="li">{`Group pages`}</em>{`.`}</li>
      </ol>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6ff2d5dd1724c88c0fae8d102ab190c3/3cc45/ec-example-segmentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVQY05XLXQrCMBAE4Nz/XIJCTyAWREFBm59NupttNmkjbRGx4IMfwzAvo5qLrrUOeZQySZnbYOqHnMcplbH+xrmo5vyoRVJK8g4RYWSRefMibUkfAiCp/ekGRjsAZo4LRPTeE1GMERbxGzMbrQ14dWjvYDrnPmciCiGsZ+/9urcQoUe1O15t99TG2H84azuAF962HbxiSHNIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6ff2d5dd1724c88c0fae8d102ab190c3/e93cc/ec-example-segmentation.webp 300w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/b0544/ec-example-segmentation.webp 600w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/68fc1/ec-example-segmentation.webp 1200w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/a2303/ec-example-segmentation.webp 1800w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/4293a/ec-example-segmentation.webp 2400w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/b41d4/ec-example-segmentation.webp 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6ff2d5dd1724c88c0fae8d102ab190c3/eed55/ec-example-segmentation.png 300w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/7491f/ec-example-segmentation.png 600w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/8537d/ec-example-segmentation.png 1200w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/d2cc9/ec-example-segmentation.png 1800w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/00711/ec-example-segmentation.png 2400w", "/static/6ff2d5dd1724c88c0fae8d102ab190c3/3cc45/ec-example-segmentation.png 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6ff2d5dd1724c88c0fae8d102ab190c3/8537d/ec-example-segmentation.png",
              "alt": "ec-example-segmentation",
              "title": "ec-example-segmentation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <br /> 2. The campaign is available on mobile devices or browser pages with a horizontal resolution of less than 600 pixels:
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8f29f83fe182d0a04b4d3648e61034d4/4d04b/ec-example-or-segmentation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz12O23LcIAxA+f9/6Uekk+SlT33Z7CbNJN7YxmCcNQaWm4Q64GY7kzNnNEJICEZEm08J0EUICRLgNdboE1wjTNrbkH2r25CvCYgIEIno59vIiMgngFISIBSKMUrtYkYs5GIWW9h8BiyItSFjISIsNd6/c9ZeKg0sBREgpoyI1Dp2ah3rLVFJKfXDQAAPHWdu/RxGviyL1to5p7Ve18u2bc65y+WyNqy11y9qfV29NXevZ0Y5quVTCGGM8d67xq3vFn0jhGCt7c7n6NxDN7BgdD+MnNflW8MYsw/oxj5/w1qrlPLW3L9/MIK6eRKifvWr77a55t5/04eQY3g892yd+rfXP4IPSopllosUu/UopWrJf0VVTtOm1K9+YM/j/Lvjh1EdBnkY5VN1PvLqic+naT6J+XlXzi//lN2y/Dge/wIg3v2qPnZtzAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8f29f83fe182d0a04b4d3648e61034d4/e93cc/ec-example-or-segmentation.webp 300w", "/static/8f29f83fe182d0a04b4d3648e61034d4/b0544/ec-example-or-segmentation.webp 600w", "/static/8f29f83fe182d0a04b4d3648e61034d4/68fc1/ec-example-or-segmentation.webp 1200w", "/static/8f29f83fe182d0a04b4d3648e61034d4/1f8d0/ec-example-or-segmentation.webp 1732w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8f29f83fe182d0a04b4d3648e61034d4/eed55/ec-example-or-segmentation.png 300w", "/static/8f29f83fe182d0a04b4d3648e61034d4/7491f/ec-example-or-segmentation.png 600w", "/static/8f29f83fe182d0a04b4d3648e61034d4/8537d/ec-example-or-segmentation.png 1200w", "/static/8f29f83fe182d0a04b4d3648e61034d4/4d04b/ec-example-or-segmentation.png 1732w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8f29f83fe182d0a04b4d3648e61034d4/8537d/ec-example-or-segmentation.png",
              "alt": "ec-example-or-segmentation",
              "title": "ec-example-or-segmentation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Segmentation rules are also available by Cookie Service Level, allowing to select by:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`Not defined`}</li>
        <li parentName="ul">{`Required`}</li>
        <li parentName="ul">{`Functional`}</li>
        <li parentName="ul">{`Personalized`}</li>
        <li parentName="ul">{`Targeting`}</li>
      </ul>
    </blockquote>
    <h2>{`Tab: `}<em parentName="h2">{`Statistics`}</em></h2>
    <p>{`The `}<em parentName="p">{`Statistics`}</em>{` Tab is a dashboard with relevant information about the campaign such as count of prints, count of accepted or refused; CTR percentage.`}</p>
    <h2>{`Tab: `}<em parentName="h2">{`Change history`}</em></h2>
    <p>{`The `}<em parentName="p">{`Change history`}</em>{` Tab is the list of changes and is used as an audit log.   `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      